import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Crown, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import './VeiledAscension.css';

const VeiledAscension = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  };

  const deityPaths = [
    { name: 'Caelumbris: Shadowed', path: '/deities/caelumbris/shadowed' },
    { name: 'Firstborn: Soulbound', path: '/deities/firstborn/soulbound' },
    { name: 'L.I.B.R.I.S.: Knowledge', path: '/deities/libris/knowledge' },
    { name: 'Nebula: Mistwalker', path: '/deities/nebula/mistwalker' },
    { name: "Queen Mab: Frozen Veil", path: '/deities/queenmab/frozenveil' },
    { name: 'Queen Titania: Wildgrowth', path: '/deities/queentitania/wildgrowth' },
    { name: 'Sacrathor: Dominion', path: '/deities/sacrathor/dominion' },
    { name: 'Valdís: Justice', path: '/deities/valdis/justice' },
    { name: 'Wildscar: Ember Fury', path: '/deities/wildscar/emberfury' }
  ];

  return (
    <div className="veiled-welcome" style={{ background: '#0a0a0a' }}>
      <motion.div 
        className="veiled-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Crown size={48} className="welcome-icon" style={{ color: '#4a9eff', marginBottom: '1rem' }} />
        <h1>The Veiled Ascension</h1>
        <div className="veiled-paths" ref={menuRef}>
          <h2 onClick={toggleMenu} style={{ cursor: 'pointer' }}>
            Veiled Paths
            <ChevronDown size={20} className={menuOpen ? 'rotate' : ''} />
          </h2>
          <div className={`veiled-links ${menuOpen ? 'active' : ''}`}>
            {deityPaths.map((deity, index) => (
              <Link 
                key={index} 
                to={deity.path}
                className="veiled-link"
                onClick={() => setMenuOpen(false)}
              >
                {deity.name}
              </Link>
            ))}
          </div>
        </div>
        <div className="content-section">
          <p>
            As the gods descend into unrelenting celestial conflict, the mortal world hangs in fragile balance. No longer walking among mortals, the gods wage their wars through proxies—champions imbued with divine authority who serve as extensions of their will. To further their influence, the gods offer their power not only to these chosen few but also to their devoted clergy, binding mortal and divine through sacred pacts. These powers, vast and transformative, come at a cost—a pledge of loyalty that intertwines the fates of gods and mortals.
          </p>
          <p>
            To accept a god's boon is to take on their mantle and bear the weight of their expectations. Each deity offers unique abilities and demands distinct sacrifices, forging a connection that empowers but also binds. To wield such gifts is to embrace both the strength and servitude they entail. Those who accept ascend beyond mortal limitations, becoming avatars of divine purpose.
          </p>
          <p>
            The choice lies before you. Each decision reverberates across the mortal realm, shaping its precarious future and defining your role in the gods' conflict. What will you choose?
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default VeiledAscension; 